
import React, { useState, useEffect } from 'react';
import { Table, Tab, Tabs, Form, InputGroup } from 'react-bootstrap'
import { BsArrowUpRight, BsArrowDownLeft,BsArrowUpLeft } from 'react-icons/bs'
import { CgSearch } from 'react-icons/cg';
import { BASE_URL } from '../config';
import { useSnackbar } from 'material-ui-snackbar-provider';
import axios from 'axios';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { formatDate } from '../components/helper/commonHelper';

const TransactionHistory = () => {
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortBy, setSortBy] = useState(""); 
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        getAdminTransaction();
    }, [currentPage, sortBy, searchQuery]);



    const chageCurrentPage = (pageType) => {
        
            if (pageType === "prev") {
                if (currentPage === 1) {
                    alert("No Page Exist Before 1");
                } else {
                    setCurrentPage(currentPage - 1);
                    getAdminTransaction();
                }
            }
    
            if (pageType === "next") {
                if (currentPage === totalPage) {
                    alert("No Page Exist After ");
                } else {
                    setCurrentPage(currentPage + 1);
                    getAdminTransaction();
                }
            }
    
        
    }

    const getAdminTransaction = async () => {
        try {
            let token = localStorage.getItem("token");
            setLoading(true);
            const axiosRes = await axios({
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
                url: `${BASE_URL}/admin/transactions?q=${searchQuery}&page=${currentPage}&limit=15&sortBy=${sortBy}`,
            });
            if (axiosRes.data.success) {
                setLoading(false);
                setTransactions(axiosRes.data.success_res.transactions);
                setTotalPage(axiosRes.data.success_res.totalPages);
            }
        } catch (err) {
            console.error("getUserTransaction [ERROR] =>", err);
        }
    }



    const handleSortChange = (event) => {
        const newSortBy = event.target.value;
         
        setSortBy(newSortBy);
        
        // You may want to reset the current page to 1 when changing the sorting option
        setCurrentPage(1);
    }

    if (loading) { return <Loader /> }

    return (
        <>
            <div className="verification_div">
                <h1 className="verification_text">Admin Transaction History</h1>
            </div>
            <div className='main_tabs_section'>
                <div className='tabs_div'>
                    {/* <Tabs
                        defaultActiveKey={activeTab}
                        id="uncontrolled-tab-example"
                        className="mb-4 tabs-bg"
                        onSelect={handleTabChange} // Add tab change event handler
                    > */}

                        {/* <Tab eventKey="UserTransaction" title="User Transaction"> */}
                        <div className='user_panel '>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Transaction Type</th>
                                            <th>Transaction Body</th>
                                            <th>Date and Time</th>
                                            <th>Debit/Credit</th>
                                            <th>Transfered Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            transactions && transactions.map((transaction, index) => (

                                                <tr key={index}>
                                                    
                                                    <td>{(transaction.transactionType).split('_').join(' ')}</td>
                                                    <td>{transaction.body}</td>
                                                    <td>{formatDate(transaction?.createdAt)}</td>
                                                    {
                                                        transaction.isDebit==false?
                                                        <td>Debited <BsArrowUpRight color='red' /></td>
                                                        :
                                                        <td>Credited <BsArrowUpLeft color='green' /></td>
                                                    }
                                                    <td>$ {transaction?.transferredAmount}</td>
                                                </tr>

                                            ))
                                        }

                                    </tbody>
                                </Table>
                            </div>
                            <div className='pagination-div'>


                                <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
                                <span> Page {currentPage} of {totalPage} </span>
                                <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
                            </div>
                        {/* </Tab> */}
                    {/* </Tabs> */}
                </div>
                <div className='search_sort_div posabs'>
                    {/* <div className='search_bar'> */}
                        {/* <InputGroup className="mb-3 posrel">
                            <div className='search_icon'>
                                <CgSearch color='var(--primary)' size={20} />
                            </div>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup> */}
                    {/* </div>
                    <div className='sortby_select'>
                        <Form.Select aria-label="Default select example" onChange={handleSortChange} value={activeTab === 'UserTransaction' ? userSortBy : venueSortBy}>
                            <option value="">Sort By</option>
                            <option value="dateDsc">Date (Newest First)</option>
                            <option value="dateAsc">Date (Oldest First)</option>
                            <option value="high">Amount (High to Low)</option>
                            <option value="low">Amount (Low to High)</option>
                        </Form.Select>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default TransactionHistory;
