import { Link, NavLink, useNavigate } from "react-router-dom";
import { RxExit } from "react-icons/rx";
import { AiOutlineFileDone, AiOutlineDollarCircle } from "react-icons/ai";
import { BsPieChartFill } from "react-icons/bs";
import { GrTransaction } from "react-icons/gr"
import { AiFillFileImage } from "react-icons/ai";


import { FiUsers } from 'react-icons/fi'
import { MdPayments, MdPlace } from 'react-icons/md'

import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { useContext } from "react";
import { Mcontext } from "../../context/Mcontext";
import { RiAdminFill, RiLockPasswordLine } from "react-icons/ri";
import { FaHistory } from "react-icons/fa";


const SideBar = () => {
  const navigate = useNavigate();
  const { authState, logout, authDispatch } = useContext(Mcontext);
  const { authError,user } = authState;

  const logoutFun = () => {
    localStorage.removeItem('token');
    authDispatch({ type: "LOGOUT" })
    navigate('/login');
    //console.log("user LOGGED OUT");
  }


  return (
    <>
      <div className="sidebar-panel" >
        <div className="sidebarhead">
          <Link className="logobar">
            <img className="logo-sidebar" src="/images/mw_logo_2.png" alt="logo" />
          </Link>
          <ul>
            <li>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/'>
                <MdOutlineDashboardCustomize />
                Dashboard
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/users'>
                <FiUsers />
                Users
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/venues'>
                <MdPlace />
                Venues
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/requests'>
                <AiOutlineFileDone />
                Verification Request
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/transactionhistory'>
                <AiOutlineDollarCircle />
                Transaction History
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/admintransactionhistory'>
                <GrTransaction />
                Admin Transaction History
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/revenue'>
                <BsPieChartFill />
                Revenue Splits
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/banner-image'>
                <AiFillFileImage />
                Banner Image
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/payout-request'>
                <MdPayments />
                Payout Requests
              </NavLink>
              <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/change-password'>
                <RiLockPasswordLine />
                Change-Password
              </NavLink>
              {
                user?.isMaster ? 
                  <>

                    <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/sub-admin'>
                      <RiAdminFill />
                      Sub Admins
                    </NavLink>
                    <NavLink onClick={() => document.body.classList.remove('menu-open')} to='/admin-logs'>
                      <FaHistory />
                      Admin Logs
                    </NavLink>
                  </> : ""
              }

              <NavLink className="logoutbtn" to="/login" onClick={() => logoutFun()}>
                <RxExit />
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
