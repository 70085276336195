import { Form, Pagination, InputGroup } from 'react-bootstrap'
import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Mcontext } from '../../context/Mcontext';
import { BsFillBuildingsFill } from 'react-icons/bs'
import { FiMapPin } from 'react-icons/fi'
import { CgSearch } from 'react-icons/cg'
import { formatDate } from '../helper/commonHelper';
import Loader from '../Loader';




const CheckinHistory = () => {

  let { userid, venueid } = useParams();
  const {
    adminState,
    getUserCheckins,
    getSingleVenueInfo,
  } = useContext(Mcontext);
  const { adminError, totalUsers } = adminState;


  const [loading, setLoading] = useState(true);
  const [boxType, setBoxType] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkins, setCheckins] = useState([]);


  useEffect(() => {
    if (userid) {
      ////console.log("LOAD USER SECTION");
      setBoxType("user")
      loadCheckins('user');
    }
    if (venueid) {
      ////console.log("LOAD VENUE SECTION");
      setBoxType("venue");
      loadCheckins('venue');
    }
  }, [currentPage])


  const loadCheckins = async (type) => {
    if (type == 'user') {
      setLoading(true);
      const checkinRes = await getUserCheckins(userid, currentPage);
      if (checkinRes?.success) {
        setLoading(false);
        setCheckins(checkinRes?.success_res?.checkins);
        setTotalPage(checkinRes?.success_res?.totalPages)
      } else {
        setLoading(false);
      }
    }
    if (type == 'venue') {
      setLoading(true);
      const checkinRes = await getSingleVenueInfo(venueid, currentPage);
      if (checkinRes?.success) {
        setLoading(false);
        setCheckins(checkinRes?.success_res?.newUsers);
        setTotalPage(checkinRes?.success_res?.totalPages)
      } else {
        setLoading(false);
      }
    }

    // //console.log("checkinRes", checkinRes)
  }

  const chageCurrentPage = (pageType) => {

    if (pageType == "prev") {
      if (currentPage == 1) {
        alert("No Page Exist Before 1")
      } else {
        setCurrentPage(currentPage - 1);
      }
    }

    if (pageType == "next") {
      if (currentPage == totalPage) {
        alert("No Page Exist After ")
      } else {
        setCurrentPage(currentPage + 1);
      }
    }

  }


  if (loading) { return <Loader /> }

  return (
    <>
      <div className='main_div_border'>
        <h5 className='main_heading_box'>Check Ins</h5>
        <div className='main_div_sortby_search'>

          <div className='search_sort_div'>
            <div className='search_bar'>
              <InputGroup className="mb-3 posrel">
                <div className='search_icon'>
                  <CgSearch color='var(--primary)' size={20} />
                </div>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className='sortby_select'>
              <Form.Select aria-label="Default select example">
                <option>Sort By</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className='detail_box'>

          {checkins && checkins.map((singleCheckin, index) => {

            
              if(boxType == "user"){
                return (
                  <>
                  <div className='detail_row request_row' key={index}>
                    <div className='left_div'>
                      <div className='image_left_div'>
                        <img src={singleCheckin?.venueId?.venuePhotos && singleCheckin?.venueId?.venuePhotos[0]?.path} width={75} height={75} />

                      </div>
                      <div className='text_div_left'>
                        <div className='top_text'>
                          <p>{singleCheckin?.venueId?.title}</p>
                        </div>
                        <div className='bottom_text'>
                          <p className='light_text'> <BsFillBuildingsFill color='#8C56FF' />&nbsp; {singleCheckin?.venueId?.venueType} &nbsp; <FiMapPin color='#8C56FF' /> &nbsp;{singleCheckin?.venueId?.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className='right_div'>
                      <h5>&nbsp;</h5>
                      <p className='light_text'>{formatDate(singleCheckin?.checkedInAt)}</p>
                    </div>
                  </div>
                </>

                )
              }

            

            
              if(boxType == "venue"){
                return(
                  <>

                  <div className='detail_row request_row' key={index}>
                    <div className='left_div'>
                      <div className='image_left_div'>
                        <img src={singleCheckin?.userId?.photo} width={75} height={75} />

                      </div>
                      <div className='text_div_left'>
                        <div className='top_text'>
                          <p>{singleCheckin?.userId?.name}</p>
                        </div>
                        <div className='top_text'>
                          <p>Total Requests : {singleCheckin?.totalRequests}</p>
                        </div>
                      </div>
                    </div>
                    <div className='right_div'>
                      <h5>&nbsp;</h5>
                      <p className='light_text'>{formatDate(singleCheckin?.checkedInAt)}</p>
                    </div>
                  </div>

                </>
                )
              } 

          })}


        </div>
        <div className='pagination_box'>
          <div className='pagination-div'>
            <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
            <span> Page {currentPage} of {totalPage} </span>
            <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
          </div>
        </div>
      </div>

    </>
  )
}

export default CheckinHistory