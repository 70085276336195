import React, { useState, useContext, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { BsPencil, BsPlusCircleFill, BsArrowUpRight } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { Form, InputGroup } from 'react-bootstrap'
import { CgSearch } from 'react-icons/cg';


import Modal from "react-bootstrap/Modal";
import { Mcontext } from "../context/Mcontext";
import VenueForm from "../components/VenueForm";
import axios from "axios";
import { BASE_URL } from "../config";
import { useSnackbar } from "material-ui-snackbar-provider";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const Venue = ({isDashboard}) => {
  const snackbar = useSnackbar();

  const { adminState, adminDispatch, getAllVenues, toogleVenueRequest } =
    useContext(Mcontext);
  const { totalVenues } = adminState;

  const [loading, setLoading] = useState(false);
  const [totalVenuePage, setTotalVenuePage] = useState(0);
  const [currentVenuePage, setCurrentVenuePage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [currentVenue, setCurrentVenue] = useState({});

  const [searchInput, setSearchInput] = useState('');
const [sortOption, setSortOption] = useState('-createdAt'); 


  useEffect(() => { }, []);

  useEffect(() => {
    loadVenues();
  }, [currentVenuePage,sortOption,searchInput])

  const VenueModal = (props) => {
    return (
      // Add venue mondal
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Venue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VenueForm completed={(e) => venueReload()} mode={"add"} />
        </Modal.Body>
      </Modal>
    );
  };
  const EditVenueModal = (props) => {
    return (
      // Add venue mondal
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Venue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VenueForm
            completed={(e) => venueReload()}
            mode={"edit"}
            venueSelected={currentVenue}
          />
        </Modal.Body>
      </Modal>
      // edit button modal
    );
  };

  const venueReload = () => {
    loadVenues();
    setModalShow(false);
    setEditModalShow(false);
  };

  const editmodal = (venue) => {
    setCurrentVenue(venue);
    setEditModalShow(true);
  };

  const deleteVenue = async (venueid) => {
    try {
      if (window.confirm("Are you sure want to Delete This Venue") === true) {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
          url: `${BASE_URL}/admin/venues/${venueid}`,
        });
        //console.log(axiosRes.data);
        if (axiosRes.data.success) {
          snackbar.showMessage(axiosRes.data.message.toUpperCase());
          loadVenues();
        }
      }
    } catch (err) {
      //console.log("deleteVenue [ERROR]", err);
      snackbar.showMessage(err.message.toUpperCase());
    }
  };

  const toggleVenue = async (venueid, type) => {
    try {
      const resData = await toogleVenueRequest(venueid, type);
      if (resData.success) {
        // adminDispatch({ type: "VENUE_UPDATED", payload: resData.success_res});
        loadVenues();
        snackbar.showMessage(resData.message.toUpperCase());
      }
    } catch (err) {
      //console.log("suspendVenue [ERROR]", err);
      snackbar.showMessage(err.message.toUpperCase());
    }
  };

  const loadVenues = async () => {
    // snackbar.showMessage("hello from snackbar")
    if(!searchInput){

      setLoading(true);
    }
    let summaryData;
    if(isDashboard){
      
      summaryData = await getAllVenues(currentVenuePage,'-totalCheckins');
    }else{
      
      summaryData = await getAllVenues(currentVenuePage,sortOption,searchInput);
      
    }
    if (summaryData?.success) {
      adminDispatch({
        type: "VENUES_LOADED",
        payload: summaryData.success_res.venues,
      });
      setTotalVenuePage(summaryData.success_res.totalPages);
      setLoading(false);
    } else {
      //console.log("error blaock");
      adminDispatch({
        type: "ERROR",
        payload: summaryData?.response?.data.message,
      });
      setLoading(false);
    }
  };



  const chageCurrentPage = (pageType) => {

    if (pageType == "prev") {
      if (currentVenuePage == 1) {
        alert("No Page Exist Before 1")
      } else {
        setCurrentVenuePage(currentVenuePage - 1);
      }
    }

    if (pageType == "next") {
      if (currentVenuePage == totalVenuePage) {
        alert("No Page Exist After ")
      } else {
        setCurrentVenuePage(currentVenuePage + 1);
      }

    }

  }


  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="venue">
      {isDashboard ? (
                    <h4 className='maintitle'>Top Venues</h4>
                ) : (
                    <h1 className="verification_text">Venues</h1>
                )}
        <div>
          <button className="btnblack_new" onClick={() => setModalShow(true)}>
            {" "}
            <BsPlusCircleFill size="24" />
            &nbsp;&nbsp;Add Venue
          </button>
        </div>
      </div>
  
 
      <VenueModal show={modalShow} onHide={() => setModalShow(false)} />
      <EditVenueModal
        show={editmodalShow}
        onHide={() => setEditModalShow(false)}
      />


{
    !isDashboard?
<div className='search_sort_div posabs search-sort'>
                    <div className='search_bar'>
                        <InputGroup className="mb-3 posrel">
                            <div className='search_icon'>
                                <CgSearch color='var(--primary)' size={20} />
                            </div>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <div className='sortby_select'>
                        <Form.Select aria-label="Default select example"  value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                        <option value="-createdAt">Sort by Date (Newest First)</option>
      <option value="createdAt">Sort by Date (Oldest First)</option>
      <option value="-totalEarnings">Sort by Total Earnings (Descending)</option>
      <option value="totalEarnings">Sort by Total Earnings (Ascending)</option>
                        </Form.Select>
                    </div>
                </div>
  :''
  }
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>Venue Name</th>
              <th>Type</th>
              {/* <th>Address</th> */}
              <th>Address</th>
              <th>Contact No.</th>
              <th>Earnings</th>
              <th>Verification Status</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {totalVenues &&
              totalVenues.map((venue, index) => (
                <>
                  <tr>

                  <Link to={`/venuedetail/${venue._id}`}><td>{venue.title}</td></Link>
                    
                    <td>{venue.venueType}</td>
                    {/* <td>{venue.address}</td> */}
                    <td>{venue.city},{venue.country}</td>
                    <td>{venue.phoneNumber}</td>
                    <td>${venue.totalEarnings}</td>
                    <td>
                      {venue.verifiedAt ? (
                        <p className="verified">Complete</p>
                      ) : (
                        <p className="not-verified">InComplete</p>
                      )}
                      {venue.suspendedAt ? (
                        <h6 style={{ fontSize: "12px" }}>SUSPENDED</h6>
                      ) : null}
                    </td>
                    <td>
                      <div className="edit">
                        <Link to={`/venuedetail/${venue._id}`}
                          className="editbtn"
                        >
                          <BsArrowUpRight
                            title="Edit Venue"
                            size={20}
                            color="#8C56FF"
                          />
                        </Link>
                        <button
                          onClick={() => editmodal(venue)}
                          className="editbtn"
                        >
                          <BsPencil
                            title="Edit Venue"
                            size={20}
                            color="#8C56FF"
                          />
                        </button>
                        {venue.suspendedAt ? (
                          <button
                            onClick={() => toggleVenue(venue._id, "approve")}
                            className="delbtn"
                          >
                            <AiFillCheckCircle
                              size={20}
                              color="green"
                              title="Unsuspend Venue"
                            />
                          </button>
                        ) : (
                          <button
                            onClick={() => toggleVenue(venue._id, "suspend")}
                            className="delbtn"
                          >
                            <AiOutlineCloseCircle
                              size={20}
                              title="Suspend Venue"
                              color="red"
                            />
                          </button>
                        )}
                        <button
                          onClick={() => deleteVenue(venue._id)}
                          className="delbtn"
                        >
                          <RiDeleteBin5Line
                            title="Delete Venue"
                            size={20}
                            color="red"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </Table>
        <div className="pagination-div">

      
        <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
        <span> Page {currentVenuePage} of {totalVenuePage} </span>
        <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
        </div>
      </div>
    </>
  );
};

export default Venue;
