import React, { useEffect, useState, useContext } from 'react'
import { Form, Pagination } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { Mcontext } from '../../context/Mcontext';
import { formatDate } from '../helper/commonHelper';
import Loader from '../Loader';


const Transactions = () => {
    let { userid, venueid } = useParams();
    const {
        adminState,
        geUserTransactions,
        getSingleVenueTransactions,
    } = useContext(Mcontext);
    const { adminError, totalUsers } = adminState;

    const [loading, setLoading] = useState(true);
    const [boxType, setBoxType] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactions, setTransactions] = useState([]);


    useEffect(() => {
        // //console.log("userid - venueid", userid, venueid);
        if (userid) {
            //console.log("LOAD USER SECTION");
            setBoxType("user");
            loadTransactions('user');
        }
        if (venueid) {
            //console.log("LOAD VENUE SECTION");
            setBoxType("venue");
            loadTransactions('venue');
        }
    }, [currentPage])


    const loadTransactions = async (type) => {
        if (type == 'user') {
            setLoading(true);
            const transactionRes = await geUserTransactions(userid, currentPage);
            if (transactionRes?.success) {
                setLoading(false);
                setTransactions(transactionRes?.success_res?.transactions);
                setTotalPage(transactionRes?.success_res?.totalPages)
            } else {
                setLoading(false);
            }
        }
        if (type == 'venue') {
            setLoading(true);
            const transactionRes = await getSingleVenueTransactions(venueid, currentPage);
            if (transactionRes?.success) {
                setLoading(false);
                setTransactions(transactionRes?.success_res?.transactions);
                setTotalPage(transactionRes?.success_res?.totalPages)
            } else {
                setLoading(false);
            }

        }

    }

    const chageCurrentPage = (pageType) => {

        if (pageType == "prev") {
            if (currentPage == 1) {
                alert("No Page Exist Before 1")
            } else {
                setCurrentPage(currentPage - 1);
            }
        }

        if (pageType == "next") {
            if (currentPage == totalPage) {
                alert("No Page Exist After ")
            } else {
                setCurrentPage(currentPage + 1);
            }

        }

    }

    if (loading) { return <Loader /> }
    return (
        <>
            <div className='main_div_border'>
                <h5 className='main_heading_box'>Transactions</h5>
                <div className='sortby_select'>
                    <Form.Select aria-label="Default select example">
                        <option>Sort By</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </div>
                <div className='detail_box'>
                    {transactions && transactions.map((singleTxn, index) => {

                        if (boxType == "user") {
                            return (
                                <>
                                    <div className='detail_row' key={index}>
                                        <p>{formatDate(singleTxn?.createdAt)}</p>
                                        <h5>$ {singleTxn.transferredAmount}</h5>
                                    </div>
                                </>
                            )
                        }

                        if (boxType == "venue") {
                            return (
                                <>
                                    <div className='detail_row' key={index}>
                                        <p>{formatDate(singleTxn?.createdAt)}</p>
                                        <h5>$ {singleTxn.transferredAmount}</h5>
                                    </div>
                                </>
                            )
                        }




                    })}
                </div>
                <div className='pagination_box'>
                    <div className='pagination-div'>
                        <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
                        <span> Page {currentPage} of {totalPage} </span>
                        <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transactions;