import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineCloseCircle, AiFillCheckCircle, AiOutlineCheck } from "react-icons/ai";
import { BsArrowUpRight } from "react-icons/bs";
import { Form, InputGroup } from 'react-bootstrap'
import { CgSearch } from 'react-icons/cg';


import { Mcontext } from '../context/Mcontext';
import { BASE_URL } from '../config';
import { useSnackbar } from 'material-ui-snackbar-provider';
import axios from 'axios';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';


const User = ({isDashboard}) => {
    const snackbar = useSnackbar();

    const {
        adminState,
        adminDispatch,
        getAllUsers,
        getSummary,
        toogleUserRequest,
    } = useContext(Mcontext);
    const { adminError, totalUsers } = adminState;

    const [loading, setLoading] = useState(false);
    const [totalUserPage, setTotalUserPage] = useState(0);
    const [currentUserPage, setCurrentUserPage] = useState(1);

    const [searchInput, setSearchInput] = useState('');
    const [sortOption, setSortOption] = useState('-createdAt'); 
    

    useEffect(() => {
        loadUsers();
    }, [currentUserPage,sortOption,searchInput])

    const deleteUser = async (userid) => {
        try {
            if (window.confirm("Are you Sure want to Delete This User") === true) {
                let token = localStorage.getItem('token');
                const axiosRes = await axios({
                    method: "DELETE",
                    headers: { Authorization: `Bearer ${token}` },
                    url: `${BASE_URL}/admin/users/${userid}`,
                })
                //console.log(axiosRes.data);
                if (axiosRes.data?.success) {
                    snackbar.showMessage(axiosRes.data.message.toUpperCase());
                    loadUsers();
                }
            }

        } catch (err) {
            //console.log("deleteUser [ERROR]", err);
            snackbar.showMessage(err.message.toUpperCase());
        }

    }

    const toggleUser = async (userid, type) => {
        try {

            const resData = await toogleUserRequest(userid, type);
            if (resData?.success) {
                // adminDispatch({ type: "VENUE_UPDATED", payload: resData.success_res});
                loadUsers();
                snackbar.showMessage(resData.message.toUpperCase());
            }
        } catch (err) {
            //console.log("suspendVenue [ERROR]", err);
            snackbar.showMessage(err.message.toUpperCase());
        }

    }



    const loadUsers = async () => {
        if(!searchInput){

            setLoading(true);
        }
        let summaryData;
        
        if(isDashboard){
      
            // summaryData = await getAllVenues(currentUserPage,'-totalCheckedIn');  // we will replace this line with below line when total checkins increases
            summaryData = await getAllUsers(currentUserPage,'-createdAt',searchInput);
        }else{
            
            summaryData = await getAllUsers(currentUserPage,sortOption,searchInput);
          }
        if (summaryData?.success) {
            adminDispatch({ type: "USERS_LOADED", payload: summaryData.success_res.users });
            setTotalUserPage(summaryData.success_res.totalPages)
            setLoading(false);
        } else {
            //console.log("error blaock")
            adminDispatch({ type: "ERROR", payload: summaryData?.response.data.message });
            setLoading(false);
        }
    }


    const chageCurrentPage = (pageType) => {

        if (pageType == "prev") {
            if (currentUserPage == 1) {
                alert("No Page Exist Before 1")
            } else {
                setCurrentUserPage(currentUserPage - 1);
            }
        }

        if (pageType == "next") {
            if (currentUserPage == totalUserPage) {
                alert("No Page Exist After ")
            } else {
                setCurrentUserPage(currentUserPage + 1);
            }

        }

    }


    if (loading) { return <Loader /> }

    return (
        <>

            <div className="verification_div">
            {isDashboard ? (
                    <h4 className='maintitle'>Top Users</h4>
                ) : (
                    <h1 className="verification_text">Users</h1>
                )}
            </div>

            {
    !isDashboard?
  <div className='search_sort_div posabs search-sort'>
                    <div className='search_bar'>
                        <InputGroup className="mb-3 posrel">
                            <div className='search_icon'>
                                <CgSearch color='var(--primary)' size={20} />
                            </div>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <div className='sortby_select'>
                        <Form.Select aria-label="Default select example"  value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                        <option value="-createdAt">Sort by Date (Newest First)</option>
      <option value="createdAt">Sort by Date (Oldest First)</option>
      <option value="-amountSpent">Sort by Amount Spent (Highest First)</option>
      <option value="amountSpent">Sort by Amount Spent (Lowest First)</option>
      {/* <option value="-totalEarnings">Sort by Total Earnings (Descending)</option>
      <option value="totalEarnings">Sort by Total Earnings (Ascending)</option> */}
                        </Form.Select>
                    </div>
                </div>
  :''
  }
            <div className='user_panel'>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No.</th>
                            <th>Amount Spent</th>
                            <th>Status</th>
                            <th>   </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            totalUsers && totalUsers?.map((user, index) => (
                                <>
                                    <tr>
                                        <td><Link to={`/userdetails/${user._id}`}>{user.name}</Link></td>
                                        <td>{user.email ? user.email : "not provided"}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.amountSpent}</td>
                                        <td>{user.suspendedAt ?
                                            <p className='not-verified'>
                                                SUSPENDED
                                            </p>
                                            :
                                            <p className='verified'>
                                                Active
                                            </p>
                                            // <>
                                            //     {
                                            //         user.verifiedAt ?
                                            //             <p className='verified'>
                                            //                 Verified
                                            //             </p>
                                            //             :
                                            //             <p>
                                            //                 Not Verified
                                            //             </p>
                                            //     }
                                            // </>

                                        }</td>
                                        <td><div className="edit">
                                        <Link to={`/userdetails/${user._id}`}
                          className="editbtn"
                        >
                          <BsArrowUpRight
                            title="Edit Venue"
                            size={20}
                            color="#8C56FF"
                          />
                        </Link>

                                            {user.suspendedAt ?
                                                <button onClick={() => toggleUser(user._id, "approve")}
                                                    className="delbtn"><AiOutlineCheck size={20} color='black'
                                                        title="Verify User" /></button>
                                                :
                                                <>
                                                    <button onClick={() => toggleUser(user._id, "suspend")}
                                                        className="delbtn"><AiOutlineCloseCircle size={20}
                                                            title="Suspend User" color='red' /></button>
                                                </>

                                            }
                                            <button onClick={() => deleteUser(user._id)} className="delbtn" title="Delete User" ><RiDeleteBin5Line color='red' size={20} /></button>
                                        </div></td>
                                    </tr>
                                </>
                            ))
                        }

                    </tbody>
                </Table>
                <div className='pagination-div'>
                    <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
                    <span> Page {currentUserPage} of {totalUserPage} </span>
                    <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
                </div>
            </div>

        </>
    )
}

export default User