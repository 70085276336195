import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Mcontext } from '../../context/Mcontext';
import { Form, Pagination, InputGroup } from 'react-bootstrap'
import { CgSearch } from 'react-icons/cg'
import { formatDate } from '../helper/commonHelper';
import Loader from '../Loader';



const RequestHistory = () => {
  let { userid, venueid } = useParams();
  const {
    adminState,
    getUserRequests,
    getSingleVenueRequests,
    getUserCheckins,
  } = useContext(Mcontext);
  const { adminError, totalUsers } = adminState;

  const [loading, setLoading] = useState(true);
  const [boxType, setBoxType] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [requests, setRequests] = useState([]);


  useEffect(() => {

    if (userid) {
      //console.log("LOAD USER SECTION");
      setBoxType("user");
      loadRequests('user');
    }
    if (venueid) {
      //console.log("LOAD VENUE SECTION");
      setBoxType("venue");
      loadRequests('venue');
    }
  }, [currentPage])


  const loadRequests = async (type) => {
    if (type == 'user') {
      setLoading(true);
      const requestsRes = await getUserRequests(userid, currentPage);
      if (requestsRes?.success) {
        setLoading(false);
        setRequests(requestsRes?.success_res?.requests);
        setTotalPage(requestsRes?.success_res?.totalPages)
      } else {
        setLoading(false);
      }
    }
    if (type == 'venue') {
      setLoading(true);
      const requestsRes = await getSingleVenueRequests(venueid, currentPage);
      if (requestsRes?.success) {
        setLoading(false);
        setRequests(requestsRes?.success_res?.requests);
        setTotalPage(requestsRes?.success_res?.totalPages)
      } else {
        setLoading(false);
      }
    }


  }

  const chageCurrentPage = (pageType) => {

    if (pageType == "prev") {
      if (currentPage == 1) {
        alert("No Page Exist Before 1")
      } else {
        setCurrentPage(currentPage - 1);
      }
    }

    if (pageType == "next") {
      if (currentPage == totalPage) {
        alert("No Page Exist After ")
      } else {
        setCurrentPage(currentPage + 1);
      }

    }

  }


  if (loading) { return <Loader /> }

  return (
    <>
      <div className='main_div_border'>
        <h5 className='main_heading_box'>Recent Requests</h5>
        <div className='main_div_sortby_search'>

          <div className='search_sort_div'>
            <div className='search_bar'>
              <InputGroup className="mb-3 posrel">
                <div className='search_icon'>
                  <CgSearch color='var(--primary)' size={20} />
                </div>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className='sortby_select'>
              <Form.Select aria-label="Default select example">
                <option>Sort By</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className='detail_box'>
          {requests && requests.map((singleRequest, index) => {

            if (boxType == "user") {
              return (
                <>
                  <div className='detail_row request_row' key={index}>
                    <div className='left_div'>
                      <div className='image_left_div'>
                        <img src={singleRequest?.songId?.songCoverImage} width={75} height={75} />
                      </div>
                      <div className='text_div_left'>
                        <div className='top_text'>
                          <p>{singleRequest?.songId?.songName}</p> &nbsp;&nbsp;
                          <p className='light_text'>{singleRequest?.songId?.songArtist}</p>
                        </div>
                        {/* <div className='bottom_text'>
                        <p className='light_text'> Requested by: James Smith</p>
                    </div> */}
                      </div>
                    </div>
                    <div className='right_div'>
                      <h5>$ {singleRequest?.amountPaid}</h5>
                      <p className='light_text'>{formatDate(singleRequest?.requestedAt)}</p>
                    </div>
                  </div>
                </>
              )
            }

            if (boxType == "venue") {
              return (
                <>
                  <div className='detail_row request_row' key={index}>
                    <div className='left_div'>
                      <div className='image_left_div'>
                        <img src={singleRequest?.songId?.songCoverImage} width={75} height={75}/>
                      </div>
                      <div className='text_div_left'>
                        <div className='top_text'>
                          <p>{singleRequest?.songId?.songName}</p> &nbsp;&nbsp;
                          <p className='light_text'>{singleRequest?.songId?.songArtist}</p>
                        </div>
                        {/* <div className='bottom_text'>
                        <p className='light_text'> Requested by: James Smith</p>
                    </div> */}
                      </div>
                    </div>
                    <div className='right_div'>
                      <h5>$ {singleRequest?.amountPaid}</h5>
                      <p className='light_text'>{formatDate(singleRequest?.requestedAt)}</p>
                    </div>
                  </div>
                </>
              )
            }





          })}
        </div>
        <div className='pagination_box'>
          <div className='pagination-div'>
            <button className='btn btn-light' onClick={() => chageCurrentPage('prev')}>Previous</button>
            <span> Page {currentPage} of {totalPage} </span>
            <button className='btn btn-light' onClick={() => chageCurrentPage('next')}>Next</button>
          </div>
        </div>
      </div>

    </>
  )
}

export default RequestHistory