import React, { useContext, useEffect } from 'react'
import '../css/style.css'
import { BsPlusCircleFill } from "react-icons/bs";
import { Table, Tab, Tabs, Form, InputGroup } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Header from '../components/FrontendPanel/header';
import { Mcontext } from '../context/Mcontext';
import { BASE_URL } from '../config';
import { useSnackbar } from 'material-ui-snackbar-provider'
import Loader from '../components/Loader';
import FieldError from "../components/Common/FieldError";


let currentImage = "";
let venueId = "";

const PayoutRequest = () => {
  const snackbar = useSnackbar()
  const {
    adminState,
    adminDispatch,
    getSummary,
    getAllPayoutRequests,
    payoutRequestApprove,
    payoutRequestReject
  } = useContext(Mcontext);
  const { adminError, totalRequests } = adminState;

  const [loading, setLoading] = useState(false);
  const [rejectmodalShow, setRejectModalShow] = useState(false);
  const [typeRequests, setTypeRequests] = useState({});
  const [comments, setComments] = useState('');
  const [error, setError] = useState();
  const [status, setStatus] = useState('');
  const [withdrawlId, setWithdrawlId] = useState('');


  useEffect(() => {
    loadRequests();
  }, [])

  const loadRequests = async () => {
    setLoading(true);
    const summaryData = await getAllPayoutRequests();
    //console.log("summary data", summaryData);
    if (summaryData?.success) {
      setLoading(false);
      adminDispatch({ type: "PAYOUTS_LOADED", payload: summaryData.success_res.requests });

    } else {
      setLoading(false);
      adminDispatch({ type: "ERROR", payload: summaryData.response.data.message })
    }
  }
  useEffect(() => {
    setLoading(true);
    let resultA = [];
    if (totalRequests.length > 0) {
      const newFilter = Object.keys(totalRequests).map((result, key) => {
        if (totalRequests[key].type.includes("venue")) {
          resultA.push(totalRequests[key]);
        };
        setTypeRequests(resultA);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [totalRequests])

  const toggleRequest = async () => {
    if (comments == '') {
      setError('Please provide Comments');
      setRejectModalShow(true);
      return false;
    }
    if (status == "approve") {
      const resData = await payoutRequestApprove(withdrawlId, comments);
      if (resData.success) {
        closeModal();
        loadRequests();
        snackbar.showMessage(resData.message.toUpperCase());
      }
    }
    if (status == "reject") {
      const resData = await payoutRequestReject(withdrawlId, comments);
      if (resData.success) {
        closeModal();
        loadRequests();
        snackbar.showMessage(resData.message.toUpperCase());

      }
    }
  }

  const closeModal = () => {
    setRejectModalShow(false);
    setWithdrawlId('');
    setStatus('');
    setComments('');
  }

  const handleOnChange = (type) => {
    let resultA = [];
    const newFilter = Object.keys(totalRequests).reduce((result, key) => {
      if (totalRequests[key].type.includes(type)) {
        resultA.push(totalRequests[key]);
      };
      setTypeRequests(resultA);
    }, []);
  };

  const handleStatus = (id, status, type) => {
    //console.log(id, status, type);
    if (status == "approve") {
      setWithdrawlId(id);
      setStatus(status);
      setRejectModalShow(true)
    }
    if (status == "reject") {
      setWithdrawlId(id);
      setStatus(status);
      setRejectModalShow(true)
    }
  }

  if (loading) { return <Loader /> }

  return (
    <>
      <div className="verification_div">
        <h1 className="verification_text">Payout Requests</h1>
      </div>
      <div className='verification_panel'>
        <Tabs
          defaultActiveKey="venue"
          id="uncontrolled-tab-example"
          className="mb-4 tabs-bg"
          onSelect={(index) => handleOnChange(index)}
        >
          <Tab eventKey="venue" title="Venue">
            <div className='user_panel '>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Venue</th>
                    <th>Bank Account Number</th>
                    <th>Account Holder Name</th>
                    <th>Amount</th>
                    <th>Fees</th>
                    <th>Payable Amount</th>
                    <th>Comments</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    typeRequests.length == 0 && <>
                      No Payout Requests!
                    </>
                  }
                  {
                    typeRequests.length > 0 && typeRequests.map((request, index) => (
                      <tr key={index}>
                        <td>
                          {request?.venueId?.title}
                        </td>
                        <td>{request?.bankAccountId?.accountNumber}</td>
                        <td>{request?.bankAccountId?.accountHolderName}</td>
                        <td>${request?.amount}</td>
                        <td>${request?.fees}</td>
                        <td>${request?.payableAmount}</td>
                        <td>{request?.comments}</td>
                        {
                          request?.approvedAt ?
                            <>
                              <td><div className="edit"><button className="approve">Approved</button></div></td>
                            </>
                            :
                            ''
                        }
                        {
                          request?.rejectedAt ?
                            <>
                              <td><div className="edit"><button onClick={() => handleStatus(request._id, "approve")} className="approve">Approve</button>
                                <button className="reject">Rejected</button></div>
                              </td>
                            </>
                            :
                            ''
                        }
                        {
                          !request?.approvedAt && !request?.rejectedAt ?
                            <>
                              <td><div className="edit">
                                <button onClick={() => handleStatus(request._id, "approve")} className="approve">Approve</button>
                                <button onClick={() => handleStatus(request._id, "reject")} className="reject">Reject</button>
                              </div></td>
                            </>
                            :
                            ''
                        }
                      </tr>
                    ))
                  }

                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="user" title="User">
            <div className='user_panel '>
              <Table responsive>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Bank Account Number</th>
                    <th>Account Holder Name</th>
                    <th>Amount</th>
                    <th>Fees</th>
                    <th>Payable Amount</th>
                    <th>Comments</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                {
                    typeRequests.length == 0 && <>
                      No Payout Requests!
                    </>
                  }
                  {
                    typeRequests.length > 0 && typeRequests.map((request, index) => (
                      <tr key={index}>
                        <td>
                          {request?.userId?.name}
                        </td>
                        <td>{request?.bankAccountId?.accountNumber}</td>
                        <td>{request?.bankAccountId?.accountHolderName}</td>
                        <td>${request?.amount}</td>
                        <td>${request?.fees}</td>
                        <td>${request?.payableAmount}</td>
                        <td>{request?.comments}</td>
                        {
                          request?.approvedAt ?
                            <>
                              <td><div className="edit"><button className="approve">Approved</button></div></td>
                            </>
                            :
                            ''
                        }
                        {
                          request?.rejectedAt ?
                            <>
                              <td><div className="edit"><button onClick={() => handleStatus(request._id, "approve")} className="approve">Approve</button>
                                <button className="reject">Rejected</button></div>
                              </td>
                            </>
                            :
                            ''
                        }
                        {
                          !request?.approvedAt && !request?.rejectedAt ?
                            <>
                              <td><div className="edit">
                                <button onClick={() => handleStatus(request._id, "approve")} className="approve">Approve</button>
                                <button onClick={() => handleStatus(request._id, "reject")} className="reject">Reject</button>
                              </div></td>
                            </>
                            :
                            ''
                        }
                      </tr>

                    ))
                  }

                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={rejectmodalShow}
        onHide={() => setRejectModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to {status} the request?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Comments</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={(e) => setComments(e?.target?.value)} value={comments} />
            <FieldError error={error} />
          </Form.Group>
          <div className='buttons_modal'>
            <button onClick={toggleRequest} className="approve" variant="primary" centered>
              Yes
            </button>
            <button onClick={closeModal} className="reject" variant="primary" centered>
              Cancel
            </button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default PayoutRequest;