import React, { useState, useEffect, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import ViewPayoutHistory from '../../components/BoxComponents/ViewPayoutHistory';
import { FaUserFriends } from 'react-icons/fa'
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import RequestHistory from '../../components/BoxComponents/RequestHistory';
import CheckinHistory from '../../components/BoxComponents/CheckinHistory';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useSnackbar } from "material-ui-snackbar-provider";
import { BASE_URL } from '../../config';
import { Mcontext } from '../../context/Mcontext';
import { useParams, Link } from 'react-router-dom';
import Transactions from '../../components/BoxComponents/Transactions';



const UserDetails = () => {

  const snackbar = useSnackbar();

  let { userid } = useParams();
  // //console.log(userid);


  const { adminState, adminDispatch, getSingleUser,getUserCurrentCheckin } = useContext(Mcontext);
  const { totalVenues } = adminState;

  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [currentCheckIn,setCurrentCheckIn] = useState({});

  useEffect(() => {
    if (userid) {
      loadSingleUser();
      getCheckIn()
    }

  }, [])

  const loadSingleUser = async () => {
    setLoading(false);
    let userRes = await getSingleUser(userid);
    ////console.log(userRes);
    if (userRes?.success) {
      setUserDetail(userRes?.success_res);
      setLoading(false);
      // toast("hello")
      snackbar.showMessage(userRes?.message);
    } else {
      setLoading(false);
      snackbar.showMessage(userRes?.message);
    }
  }

  const getCheckIn = async() =>{
    let checkInRes = await getUserCurrentCheckin(userid);
    if(checkInRes?.success){
      setCurrentCheckIn(checkInRes?.success_res);
    }else{
      let checkin={}
      checkin.venueId  = {
        title:"Not Checked IN",
        venuePhoto:"null"
      }
      setCurrentCheckIn(checkin)
    }
  }

  if (loading) { return <Loader /> }
  return (
    <>
      <div className="body-main">
        <div className="title-bar">
          <h2><Link to="/users"><HiOutlineArrowNarrowLeft /></Link>&nbsp;User Details</h2>
        </div>

        <div className='clubbar'>
          <div className='clubleft'>
            <h4>{userDetail?.user?.name}</h4> &nbsp;
            <h6 className='color_primary'><FaUserFriends color='#8C56FF' />  &nbsp; {userDetail?.totalFriends} Friends</h6>


          </div>
        </div>

        <div className='d-flex align-items-center flex-wrap'>
          <div className='venuebox me-5'>
            <h5>Profile Picture</h5>
            <div className='profilepic'>
              <img src={userDetail?.user?.photo} alt='icon' />
            </div>
          </div>
          <div className='venuebox me-5'>
            <h5>Banner Image</h5>
            <div className='venue-media'>
              <img src={userDetail?.user?.coverphoto} alt='icon' />
            </div>
          </div>
          <div className='venuebox'>
            <h5>Currently CheckedIn At</h5>
              <p>{currentCheckIn?.venueId?.title}</p>
              <img src={currentCheckIn?.venueId?.venuePhoto} alt='icon' height={75} width={75}/>
           
          </div>
        </div>

        <div className='venuebox'>
          <h5>Location</h5>
          <p>New York</p>
        </div>
        <div className='venuebox'>
          <h5>Email</h5>
          <p>{userDetail?.user?.email}</p>
        </div>
        <div className='venue_detail_boxes'>
          <Row>
            <Col md={6}>
              <ViewPayoutHistory />
            </Col>
            <Col md={6}>
              <Transactions />
            </Col>
            <Col md={6}>
              <RequestHistory />
            </Col>
            <Col md={6}>
              <CheckinHistory />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default UserDetails