export const initialAdminState = {
  totalRequests: [],
  totalUsers: [],
  totalVenues: [],
  revenueStats: null,
  adminError: null,
  adminstate: "initial state"
};

export const adminReducer = (state, action) => {
  switch (action.type) {
    case "REQUESTS_LOADED":
      return {
        ...state,
        adminstate: action.payload,
      };
    case "VENUES_LOADED":
      return {
        ...state,
        totalVenues: action.payload,
      };
    case "USERS_LOADED":
      return {
        ...state,
        totalUsers: action.payload,
        adminstate: "changed to logout"
      };
    case "PAYOUTS_LOADED":
      return {
        ...state,
        totalRequests: action.payload,
      };
    case "VENUE_UPDATED":
      return {
        ...state,
        totalVenues: action.payload,
      };
    case "REVENUE_UPDATED":
      return {
        ...state,
        revenueStats: action.payload,
      };
    case "ERROR":
      //console.log("error dispatched", action.payload)
      return {
        ...state,
        adminError: action.payload
      };
    default:
      return state;
  }
};