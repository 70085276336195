import React, { useReducer, useEffect } from 'react';
import axios from "axios";
import { BASE_URL } from '../config';
import { Link, useNavigate } from 'react-router-dom';

import {
  login,
  logout,
  refreshUser,
} from './functions/AuthFunctions';

import {
  getSummary,

  getAllUsers,
  getSingleUser,
  geUserTransactions,
  getUserRequests,
  getUserCheckins,
  getUserCurrentCheckin,


  getAllVenues,
  getSingleVenue,
  getSingleVenueInfo,
  getSingleVenueRequests,
  getSingleVenueTransactions,


  getAllPayoutRequests,
  toogleVenueRequest,
  toogleUserRequest,
  payoutRequestApprove,
  payoutRequestReject,
  getRevenue,
  uploadImages

} from './functions/AdminFunction'

import { authReducer, initialAuthState } from './reducers/AuthReducer';
import { adminReducer, initialAdminState } from './reducers/AdminReducer';


export const Mcontext = React.createContext();

export const Mprovider = (props) => {
  const navigate = useNavigate();
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);
  const [adminState, adminDispatch] = useReducer(adminReducer, initialAdminState);

  useEffect(() => {
    loadUser();
  }, [])
  
  const loadUser = async () => {
    try {
      let token = localStorage.getItem('token');
      if (!token) {
        //console.log("No token found logging out");
        authDispatch({ type: "LOGOUT" });
        // navigate('/login');
        return false;
      }
      //console.log("loadUser admin runs");
      const axiosRes = await axios({
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
        url: `${BASE_URL}/admin/get-admin`,
      });
      if (axiosRes?.data?.success) {
        //console.log("loadUser [SUCCESS]", axiosRes.data.success_res);
        authDispatch({ type: "ADMIN_LOADED", payload: axiosRes.data.success_res });
      } else {
        authDispatch({ type: "LOGOUT" });
        // navigate('/login');
      }
      // return axiosRes?.data;
    } catch (err) {
      authDispatch({ type: "LOGOUT" });
      // navigate('/login');  
      //console.log("loadUser [ERROR]", err)
    }
  }

  return (
    <Mcontext.Provider
      value={{
        // auth Reducer state
        authState,
        authDispatch,

        // auth global functions
        login,
        logout,
        refreshUser,
        loadUser,

        // admin Reducer state
        adminState,
        adminDispatch,

        //admin global functions
        getSummary,

        getAllUsers,
        getSingleUser,
        geUserTransactions,
        getUserRequests,
        getUserCheckins,
        getUserCurrentCheckin,

        getAllVenues,
        getSingleVenue,
        getSingleVenueInfo,
        getSingleVenueRequests,
        getSingleVenueTransactions,

        toogleVenueRequest,
        toogleUserRequest,
        getRevenue,
        uploadImages,
        getAllPayoutRequests,
        payoutRequestApprove,
        payoutRequestReject

      }}
    >
      {props.children}
    </Mcontext.Provider>
  );

}