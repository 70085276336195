
import firebase from 'firebase/compat/app';
import "firebase/compat/auth"
import 'firebase/compat/firestore'

//seraphic music ways
const firebaseConfig = {
  apiKey: "AIzaSyDYHJA5R4uwVsS_J_skqOV9tAmstScWxSA",
  authDomain: "musicways-1.firebaseapp.com",
  projectId: "musicways-1",
  storageBucket: "musicways-1.appspot.com",
  messagingSenderId: "140390791839",
  appId: "1:140390791839:web:a15bd2b33ee98720bd00aa",
  measurementId: "G-KWYJY1H5Z5"
};

firebase.initializeApp(firebaseConfig);

// Export Firebase modules you'll use (optional)
const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };