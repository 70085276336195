import axios from "axios";
import { BASE_URL } from "../../config";


//summary
export const getSummary = async () => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin`,
        });
        //console.log("getSummary [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        return err;
        //console.log("getSummary [ERROR] =>", err)
    }
}


//venue functions
export const getAllVenues = async (page,sort,query) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "GET",
            url: `${BASE_URL}/admin/venues?page=${page}&limit=15&sort=${sort}&search=${query}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        //console.log("getAllVenues [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getAllVenues [ERROR]", err)
    }
}

export const getSingleVenue = async (venueid) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "GET",
            url: `${BASE_URL}/venues/singlevenue/${venueid}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        //console.log("getSingleVenue [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getSingleVenue [ERROR]", err)
    }
}


export const getSingleVenueInfo = async (venueid,page) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "GET",
            url: `${BASE_URL}/venues/singlevenue/${venueid}?type=people&q=&page=${page}&limit=10`,
            headers: { Authorization: `Bearer ${token}` },
        });
        //console.log("getSingleVenueInfo [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getSingleVenueInfo [ERROR]", err)
    }
}


export const getSingleVenueRequests = async (venueid,page,requestType) => {
    try {
        let requestType = "all"
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "GET",
            url: `${BASE_URL}/venues/${venueid}/requests/?page=${page}&limit=5&type=${requestType}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        //console.log("getSingleVenueRequests [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getSingleVenueRequests [ERROR]", err)
    }
}

export const getSingleVenueTransactions = async (venueid,page) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/singlevenue/transactions/${venueid}?q=y&page=${page}&limit=10`,
        });
        //console.log("getSingleVenueTransactions [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getSingleVenueTransactions [ERROR] =>", err)
    }
}

//user functions
export const getAllUsers = async (page,sortOptions,query) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/users?page=${page}&limit=20&sortOptions=${sortOptions}&search=${query}`,
        });
        // console.log("getAllUsers [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getAllUsers [ERROR] =>", err)
    }
}

export const getSingleUser = async (userid) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/users/single/${userid}`,
        });
        //console.log("getSingleUser [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getSingleUser [ERROR] =>", err)
    }
}

export const geUserTransactions = async (userid,page) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/singleuser/transactions/${userid}?q=y&page=${page}&limit=10`,
        });
        //console.log("geUserTransactions [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("geUserTransactions [ERROR] =>", err)
    }
}

export const getUserRequests = async (userid,page) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/singleuser/requests/${userid}?q&page=${page}&limit=5`,
        });
        //console.log("getUserRequests [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getUserRequests [ERROR] =>", err)
    }
}

export const getUserCheckins = async (userid,page) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/singleuser/checkins/${userid}?q&page=${page}&limit=5`,
        });
        //console.log("getUserCheckins [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getUserCheckins [ERROR] =>", err)
    }
}

export const getUserCurrentCheckin = async (userid) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/singleuser/currentcheckin/${userid}`,
        });
        //console.log("getUserCurrentCheckin [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getUserCurrentCheckin [ERROR] =>", err)
    }
}


//pure admin functions
export const toogleVenueRequest = async (venueid, type) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "PUT",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/venues/${venueid}`,
            data: { "type": type }
        });
        //console.log("toogleVenueRequest", axiosRes?.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("toogleVenueRequest =>", err)
    }
}

export const toogleUserRequest = async (userid, type) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "PUT",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/users/${userid}`,
            data: { "type": type }
        });
        //console.log("toogleVenueRequest", axiosRes?.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("toogleVenueRequest =>", err)
    }
}

export const getRevenue = async () => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/revenue`,
        });
        //console.log("getRevenue [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {

        //console.log("getRevenue [ERROR] =>", err);
        return err;
    }
}

export const uploadImages = async (imageData) => {
    try {
        let token = localStorage.getItem("token");

        const axiosRes = await axios({
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/venues/upload-image`,
            data: imageData,
        });
        //console.log("uploadImages [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("login error in authfunction =>", err)
    }
}

export const getAllPayoutRequests = async () => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "GET",
            url: `${BASE_URL}/admin/payouts`,
            headers: { Authorization: `Bearer ${token}` },
        });
        //console.log("getAllPayoutRequests [SUCCESS]", axiosRes.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("getAllPayoutRequests [ERROR]", err)
    }
}

export const payoutRequestApprove = async (withdrawlId, comments) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/payout/approve/${withdrawlId}`,
            data: { "comments": comments }
        });
        //console.log("payoutrequestStatusChange", axiosRes?.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("payoutrequestStatusChange =>", err)
    }
}

export const payoutRequestReject = async (withdrawlId, comments) => {
    try {
        let token = localStorage.getItem("token");
        const axiosRes = await axios({
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            url: `${BASE_URL}/admin/payout/reject/${withdrawlId}`,
            data: { "comments": comments }
        });
        //console.log("payoutrequestStatusChange", axiosRes?.data);
        return axiosRes?.data;
    } catch (err) {
        //console.log("payoutrequestStatusChange =>", err)
    }
}






